import React from 'react';

const LoadingCircle = ({ iconColor = white, iconWidth = 40, iconHeight = 40 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth} height={iconHeight} viewBox="0 0 24 24">
      <path fill="none" stroke={iconColor} strokeDasharray="15" strokeDashoffset="15" strokeLinecap="round" strokeWidth="2" d="M12 3C16.9706 3 21 7.02944 21 12">
        <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.3s" values="15;0"/>
        <animateTransform attributeName="transform" dur="1.5s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/>
      </path>
    </svg>
  );
};

export default LoadingCircle;
