'use client'

import { Icon } from '@iconify/react';
import React, { useEffect, useRef, useState } from 'react';
import LoadingCircle from './Loading';
import "./VrijemeMap.css";
import mapWeatherCodeToIcon from '../utils/CodeToIcon';

const WindyMap = ({jadrankoMultiData = null}) => {
  const [windyAPI, setWindyAPI] = useState(null);
  const [weatherTimeline, setWeatherTimeline] = useState(null);
  const [currentHour, setCurrentHour] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const [selectedLayer, setSelectedLayer] = useState('wind');
  const [layerHovered, setLayerHovered] = useState(null);

  const [legendVisible, setLegendVisible] = useState(true);
  const [legendStyle, setLegendStyle] = useState(null);
  const [legendContent, setLegendContent] = useState(['km/h', '0', '5', '10', '20', '30', '40', '60']);

  const [isFullscreen, setFullscreen] = useState(false);

  const [markers, setMarkers] = useState([]);

  const [weatherData, setWeatherData] = useState(null);

  const [mobileSelectorOpen, setMobileSelectorOpen] = useState(false);

  const citiesCoordinates = {
    zagreb: [45.8150, 15.9785],
    varazdin: [46.3057, 16.3366],
    daruvar: [45.5906, 17.2250],
    slavonskibrod: [45.1603, 18.0150],
    osijek: [45.5511, 18.6939],
    rijeka: [45.3271, 14.4422],
    pula: [44.8666, 13.8496],
    zadar: [44.1194, 15.2314],
    split: [43.5081, 16.4402],
    gospic: [44.5469, 15.3744],
    sibenik: [43.7272, 15.9058],
    dubrovnik: [42.6507, 18.0944]
  };

  useEffect(() => {
    if (windyAPI) {
      windyAPI.map.invalidateSize();
    }
  }, [isFullscreen])

  const calculateWeatherTimeline = (unixTimestamp) => {
    // Floor the timestamp to the last hour
    const currentHourTimestamp = Math.floor(unixTimestamp / (60 * 60 * 1000)) * (60 * 60 * 1000);

    const timeline = [currentHourTimestamp];

    for (let i = 3; i < 96; i = i + 3) {
      const nextHour = currentHourTimestamp + (i * 60 * 60 * 1000);
      timeline.push(nextHour);
    }

    return timeline;
  };

  const formatTime = (timestamp) => {
    const zagrebTime = new Date(timestamp);
    const options = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'Europe/Zagreb' };

    let formattedTime = zagrebTime.toLocaleTimeString('en-US', options);

    if (formattedTime.startsWith('24')) {
      formattedTime = formattedTime.replace('24', '00');
    }

    return formattedTime;
  };


  const isSameDay = (currentTimestamp, index) => {
    const previousTimestamp = index > 0 ? weatherTimeline[index - 1] : null;

    if (!previousTimestamp) {
      return false;
    }

    const currentDate = new Date(currentTimestamp);
    const previousDate = new Date(previousTimestamp);

    return (
      currentDate.getDate() === previousDate.getDate() &&
      currentDate.getMonth() === previousDate.getMonth() &&
      currentDate.getFullYear() === previousDate.getFullYear()
    );
  };

  const getAlternateShade = (index) => {
    const firstDay = new Date(weatherTimeline[0]).setHours(0, 0, 0, 0);

    const currentTimestamp = weatherTimeline[index];
    const currentDay = new Date(currentTimestamp).setHours(0, 0, 0, 0);

    return (currentDay - firstDay) % (2 * 24 * 60 * 60 * 1000) === 0 ? true : false;

  }

  const getMonthInMMFormat = (date) => {
    const month = new Date(date).getMonth() + 1;
    return month < 10 ? `0${month}` : `${month}`;
  };

  const formatDate = (timestamp, index) => {
    const zagrebTime = new Date(timestamp);

    const shouldIncludeDate = !isSameDay(timestamp, index);

    if (shouldIncludeDate) {
      const options = { weekday: 'long', day: 'numeric' };
      const formattedDate = zagrebTime.toLocaleString('hr-HR', options);

      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1).replace(',', '').replace(/\s*\.*$/, '') + '.' + getMonthInMMFormat(timestamp);
    }

    return '';
  };

  const selectTimestamp = (index) => {
    setCurrentHour(index);
    windyAPI.store.set('timestamp', weatherTimeline[index]);
  };

  useEffect(() => {
    let interval;

    if (isPlaying && windyAPI) {
      interval = setInterval(() => {
        const nextIndex = (currentHour + 1) % weatherTimeline.length;
        windyAPI.store.set('timestamp', weatherTimeline[nextIndex]);

        setCurrentHour(nextIndex);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isPlaying, currentHour, weatherTimeline]);


  const handlePlayPauseClick = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  useEffect(() => {
    if (markers.length > 0 && weatherData) {
      Object.keys(citiesCoordinates).forEach((city, index) => {
        const cityWeatherData = weatherData[city];
        if (cityWeatherData && cityWeatherData.length > 0) {
          const weatherAtCurrentHour = cityWeatherData[currentHour];
          const tempDisplay = weatherAtCurrentHour ? weatherAtCurrentHour.temp : '?';
          const hourly = new Date(weatherTimeline[currentHour]).getHours();
          const isNight = hourly >= 22 || hourly < 4;

          const iconDisplay = mapWeatherCodeToIcon(weatherAtCurrentHour ? weatherAtCurrentHour.pojava : '?', isNight);

          const newIcon = L.divIcon({
            className: 'custom-marker',
            html: `
                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <img style="height: 25px; width: auto;" src="${iconDisplay}" />
                            <p style="color: white; margin: 0;">${tempDisplay}°</p>
                        </div>`,
            iconSize: [50, 50],
            iconAnchor: [25, 50],
          });

          markers[index].setIcon(newIcon);
        }
      });
    }
  }, [currentHour, weatherData, markers]);

  useEffect(() => {
    if (windyAPI && markers.length > 0) {
      //for each marker in the array, add it to the map
      markers.forEach(marker => {
        marker.setIcon(L.divIcon({
          className: 'custom-marker',
          html: `<div></div>`,
          iconSize: [50, 50],
          iconAnchor: [15, 15],
        }));
        marker.addTo(windyAPI.map);
      });

    }
  }, [markers]);

  useEffect(() => {
    const getWeatherData = async () => {

      setTimeout(() => {
        setWeatherData(jadrankoMultiData);
      }, 1500);
    }

    getWeatherData();
  }, [windyAPI, markers])


  useEffect(() => {
    const checkWindyInit = async () => {
      const options = {
        key: '7M3RrjQoXpRi5FyicD2SHtc3nqfniwDA', // Replace with your Windy API key
        verbose: false,
        lat: 44.47,
        lon: 16.13,
        zoom: 7,
      };

      const initWindyMap = async () => {
        try {
          await new Promise((resolve, reject) => {
            windyInit(options, (api) => {
              if (api) {
                setWindyAPI(api);

                setWeatherTimeline(calculateWeatherTimeline(api.store.get('timestamp')));

                api.store.set('overlay', 'wind');
                setLegendStyle(document.getElementById('legend-mobile').style.background.replace('to right', 'to bottom'));

                document.getElementById('mobile-ovr-select').style.display = 'none';
                document.getElementById('embed-zoom').style.display = 'none';
                document.getElementById('progress-bar').style.display = 'none';
                document.getElementById('legend-mobile').style.display = 'none';
                document.getElementById('logo').style.display = 'none';

                const markersArray = [];

                for (const [city, coordinates] of Object.entries(citiesCoordinates)) {
                  const marker = L.marker(coordinates);
                  markersArray.push(marker);
                }

                setMarkers(markersArray);

                // Load croatia.geojson into object
                fetch('/croatia.geojson')
                  .then(response => response.json())
                  .then(data => {
                    let myStyle = {
                      color: '#000000',
                      weight: 1,
                      opacity: 0.3,
                      fillOpacity: 0.1,
                      fillColor: '#78CDF2',
                    };

                    L.geoJSON(data, {
                      style: myStyle,
                    }).addTo(api.map);
                  });

                resolve();
              } else {
                reject(new Error('Failed to initialize Windy API.'));
              }
            });
          });
        } catch (error) {
          console.error('Error initializing Windy API:', error);
        }
      };

      const pollWindyInit = setInterval(() => {
        if (typeof windyInit !== 'undefined') {
          clearInterval(pollWindyInit);
          initWindyMap();
        }
      }, 300); // Polling every 100ms

      // Optional: Add a timeout to stop polling after a certain period
      setTimeout(() => {
        clearInterval(pollWindyInit);
        console.warn('Timed out waiting for windyInit to be defined.');
      }, 10000); // Stop polling after 10 seconds
    };

    checkWindyInit();
  }, []);


  const setLayer = (layer) => {
    //'wind', 'temp'
    if (windyAPI) {
      windyAPI.store.set('overlay', layer);
      setSelectedLayer(layer);
      if (layer !== 'ptype') {
        setTimeout(() => {
          const legendStyle = document.getElementById('legend-mobile').style.background.replace('to right', 'to bottom');
          setLegendStyle(legendStyle);
        }, 0);
      }
      switch (layer) {
        case 'temp':
          setLegendContent(['°C', '-20', '-10', '0', '10', '20', '30', '40']);
          break;
        case 'wind':
          setLegendContent(['km/h', '0', '5', '10', '20', '30', '40', '60']);
          break;
        case 'rain':
          setLegendContent(['mm', '1.5', '2', '3', '7', '10', '20', '30']);
          break;
        case 'clouds':
          setLegendContent(['mm', '1.5', '2', '3', '7', '10', '20', '30']);
          break;
        case 'satellite':
          setLegendContent(['K', '240', '230', '220', '210', '200']); //TODO: correct due to unusual amount of data
          break;
        case 'pm2p5':
          setLegendContent(['µg/m³', '0', '10', '20', '100', '1000']); //TODO: correct due to unusual amount of data
          break;
      }
    }
  };

  const returnToCroatia = () => {
    if (windyAPI) {
      windyAPI.map.setView([44.47, 16.13], 7);
    }
  }


  const zoomIn = () => {
    if (windyAPI) {
      windyAPI.broadcast.emit('zoomIn');
    }
  }

  const zoomOut = () => {
    if (windyAPI) {
      windyAPI.broadcast.emit('zoomOut');
    }
  }

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const highlightedDiv = scrollContainerRef.current.querySelector('.highlighted');
      if (highlightedDiv) {
        const containerWidth = scrollContainerRef.current.offsetWidth;
        const highlightedDivWidth = highlightedDiv.offsetWidth;
        const scrollPosition = highlightedDiv.offsetLeft - (containerWidth) / 2 - 12;
        scrollContainerRef.current.scrollLeft = scrollPosition;
      }
    }
  }, [currentHour]);

  const mapCurrentLayerToText = (layer) => {
    switch (layer) {
      case 'temp':
        return ['Temperatura', "/VrijemeMap/temperature.webp", "fluent:temperature-24-regular"];
      case 'wind':
        return ['Vjetar', "/VrijemeMap/wind.webp", "tabler:wind"];
      case 'rain':
        return ['Kiša i grmljavina', "/VrijemeMap/rain.webp", "ph:drop"];
      case 'ptype':
        return ['Radar', "/VrijemeMap/radar.webp", "gala:radar"];
      case 'clouds':
        return ['Naoblaka', "/VrijemeMap/clouds.webp", "material-symbols:cloud-outline"];
      case 'pm2p5':
        return ['Kvaliteta zraka', "/VrijemeMap/airpollution.webp", "material-symbols:factory-outline"];
    }
  }

  const mapSelectorElements = () => {
    const layers = ["temp", "wind", "rain", "ptype", "clouds", "pm2p5"];

    const result = [];

    layers.forEach((layer, index) => {
      const [text, image, icon] = mapCurrentLayerToText(layer);

      result.push(
        <button key={'layer-mobile-selector-' + layer} id={'layer-mobile-selector-' + layer} className='relative' onClick={() => setLayer(layer)}>
          <img className='aspect-[4/3] object-cover' src={image} />
          <div className={`absolute top-[3px] z-[1002] left-[3px] flex items-center justify-center h-[24px] w-[24px] ${selectedLayer === layer ? 'bg-[#0287C2]' : 'bg-white'} rounded-full`} style={{ boxShadow: "0px 0px 4px 0px #00000040" }}>
            <Icon className={`${selectedLayer === layer ? 'text-white' : 'text-black'}`} icon={icon} />
          </div>
          <p className={`text-left text-sm ${selectedLayer === layer ? 'text-[#0287C2]' : 'text-black'}`}>{text}</p>
        </button>
      );
    })

    return result;
  }


  return (

    <div className={`flex-grow ${isFullscreen ? 'h-[100vw + 45px]' : 'md:h-[660px] h-[480px]'}`}>
      <div className="flex items-center bg-white rounded-t h-[44px]">
        <p className="px-4 pr-3 text-sm font-semibold text-[#083e56] uppercase">INTERAKTIVNA VREMENSKA KARTA</p>
        <button
          className="flex items-center underline text-sm font-semibold text-[#0287C2] hover:text-[#02a7f1] transition duration-200"
          onClick={() => setFullscreen(true)}
        >
          Otvori
        </button>
      </div>
      <div className="bg-white rounded-b overflow-hidden">
        <div className={`z-[200] ${isFullscreen ? 'fixed inset-0' : 'relative'}`}>
          <div id="windy" className={`${isFullscreen ? 'h-screen' : 'md:h-[615px] h-[435px]'}`} style={{ width: '100%' }}>
            {/* Map content goes here */}
          </div>



          {(!weatherTimeline || !windyAPI) ? (
            <div style={{ position: 'absolute', bottom: '50%', left: '50%', transform: 'translateX(-50%) translateY(50%)', zIndex: 401 }}>
              <LoadingCircle iconHeight='100px' iconWidth='100px' iconColor="white" />
            </div>
          ) : (
            <>
              <div className={`md:hidden ${!isFullscreen ? 'hidden' : ''} absolute w-screen top-[0px] z-[1001]`}>
                <div className='flex justify-center items-center bg-white h-[44px] px-[12px] '>
                  <div className='flex items-center justify-center h-[24px] w-[24px] bg-[#0287C2] rounded-full'>
                    <Icon className='text-white' icon={mapCurrentLayerToText(selectedLayer)[2]} />
                  </div>
                  <p className='pl-1 text-sm'>{mapCurrentLayerToText(selectedLayer)[0]}</p>
                  <div className='ml-auto flex items-center justify-center h-[24px] w-[24px]' onClick={() => setMobileSelectorOpen(!mobileSelectorOpen)}>
                    <Icon className='text-xl' icon={`${mobileSelectorOpen ? 'material-symbols:close' : 'iconamoon:menu-burger-horizontal'}`} style={{
                      transform: mobileSelectorOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                      transition: 'transform 0.3s ease',
                    }} />
                  </div>
                </div>

                {mobileSelectorOpen ? <div className='bg-white w-full px-[12px] grid grid-cols-3 gap-2 py-2'>
                  {mapSelectorElements()}
                </div> : <></>}
              </div>
              <div className={`${isFullscreen ? '' : 'hidden'} right-[12px] top-[56px] md:top-[12px] lg:right-[24px] lg:top-[24px]`} style={{ position: 'absolute', zIndex: '1000' }}>
                <div className='hidden md:inline-block flex flex-col bg-white rounded-full text-2xl w-[35px] py-2 text-center items-center' style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}>
                  <button onClick={() => zoomIn()} className='pb-4'><Icon icon="ph:plus" /></button>
                  <button onClick={() => zoomOut()}><Icon icon="ph:minus" /></button>
                </div>
                <button
                  className={`inline-block md:mt-1.5 flex justify-center items-center w-[24px] md:w-[35px] h-[24px] md:h-[35px] bg-white rounded-full transition duration-200 hover:opacity-90`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}
                  onClick={() => returnToCroatia()}
                  onMouseEnter={() => setLayerHovered('initialLocation')}
                  onMouseLeave={() => setLayerHovered(null)}
                >
                  <Icon className='md:text-2xl' icon="grommet-icons:map-location" />
                </button>
              </div>

              <div className='hidden md:inline-block right-[12px] lg:right-[24px] top-[123px] lg:top-[135px]' style={{ position: 'absolute', transform: 'translateY(-50%)', zIndex: '999' }}>
                <div
                  className={`my-1 flex justify-center items-center w-[155px] h-[35px] bg-white rounded-full ${(layerHovered === 'initialLocation') ? 'opacity-90 fade-out' : 'w-[2px] opacity-0'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}
                >
                  <p className='text-xs pr-[35px]'>Početna lokacija</p>
                </div>
              </div>

              <div className={`pointer-events-none ${isFullscreen ? 'inline-block' : 'hidden'} right-[12px] lg:right-[24px]`} style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', zIndex: '999' }}>
                <div
                  className={`my-1 flex justify-center items-center w-[155px] h-[35px] bg-white rounded-full ${(layerHovered === 'temp' || selectedLayer === 'temp') ? 'opacity-90 fade-out' : 'w-[2px] opacity-0'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}
                >
                  <p className='text-xs pr-[35px]'>Temperatura</p>
                </div>

                <div
                  className={`my-1 flex justify-center items-center w-[155px] h-[35px] bg-white rounded-full ${(layerHovered === 'wind' || selectedLayer === 'wind') ? 'opacity-90 fade-out' : 'w-[2px] opacity-0'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}
                >
                  <p className='text-xs pr-[35px]'>Vjetar</p>
                </div>

                <div
                  className={`my-1 flex justify-center items-center w-[155px] h-[35px] bg-white rounded-full ${(layerHovered === 'rain' || selectedLayer === 'rain') ? 'opacity-90 fade-out' : 'w-[2px] opacity-0'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}
                >
                  <p className='text-xs pr-[35px]'>Kiša i grmljavina</p>
                </div>

                <div
                  className={`my-1 flex justify-center items-center w-[155px] h-[35px] bg-white rounded-full ${(layerHovered === 'ptype' || selectedLayer === 'ptype') ? 'opacity-90 fade-out' : 'w-[2px] opacity-0'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}
                >
                  <p className='text-xs pr-[35px]'>Radar</p>
                </div>

                {/* <div
                  className={`my-1 flex justify-center items-center w-[155px] h-[35px] bg-white rounded-full ${(layerHovered === 'satellite' || selectedLayer === 'satellite') ? 'opacity-90 fade-out' : 'w-[2px] opacity-0'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}
                >
                  <p className='text-xs pr-[35px]'>Satelit</p>
                </div> */}

                <div
                  className={`my-1 flex justify-center items-center w-[155px] h-[35px] bg-white rounded-full ${(layerHovered === 'clouds' || selectedLayer === 'clouds') ? 'opacity-90 fade-out' : 'w-[2px] opacity-0'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}
                >
                  <p className='text-xs pr-[35px]'>Naoblaka</p>
                </div>

                <div
                  className={`my-1 flex justify-center items-center w-[155px] h-[35px] bg-white rounded-full ${(layerHovered === 'pm2p5' || selectedLayer === 'pm2p5') ? 'opacity-90 fade-out' : 'w-[2px] opacity-0'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}
                >
                  <p className='text-xs pr-[35px]'>Kvaliteta zraka</p>
                </div>

              </div>

              <div className={`${isFullscreen ? 'inline-block' : 'hidden'} right-[12px] lg:right-[24px]`} style={{ position: 'absolute', transform: 'translateY(-50%)', top: '50%', zIndex: '1000' }}>
                <button
                  className={`my-1 flex justify-center items-center w-[35px] h-[35px] rounded-full transition duration-200 ${selectedLayer === 'temp' ? 'bg-[#0287c2] text-white font-semibold' : 'bg-white text-black'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.25)" }}
                  onClick={() => setLayer('temp')}
                  onMouseEnter={() => setLayerHovered('temp')}
                  onMouseLeave={() => setLayerHovered(null)}
                >
                  <Icon className='text-3xl' icon="fluent:temperature-24-regular" />
                </button>

                <button
                  className={`my-1 flex justify-center items-center w-[35px] h-[35px] rounded-full transition duration-200 ${selectedLayer === 'wind' ? 'bg-[#0287c2] text-white font-semibold' : 'bg-white text-black'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.25)" }}
                  onClick={() => setLayer('wind')}
                  onMouseEnter={() => setLayerHovered('wind')}
                  onMouseLeave={() => setLayerHovered(null)}
                >
                  <Icon className='text-2xl' icon="tabler:wind" />
                </button>

                <button
                  className={`my-1 flex justify-center items-center w-[35px] h-[35px] rounded-full transition duration-200 ${selectedLayer === 'rain' ? 'bg-[#0287c2] text-white font-semibold' : 'bg-white text-black'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.25)" }}
                  onClick={() => setLayer('rain')}
                  onMouseEnter={() => setLayerHovered('rain')}
                  onMouseLeave={() => setLayerHovered(null)}
                >
                  <Icon className='text-2xl' icon="ph:drop" />
                </button>

                <button
                  className={`my-1 flex justify-center items-center w-[35px] h-[35px] rounded-full transition duration-200 ${selectedLayer === 'ptype' ? 'bg-[#0287c2] text-white font-semibold' : 'bg-white text-black'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.25)" }}
                  onClick={() => setLayer('ptype')}
                  onMouseEnter={() => setLayerHovered('ptype')}
                  onMouseLeave={() => setLayerHovered(null)}
                >
                  <Icon className='text-2xl' icon="gala:radar" />
                </button>

                {/* <button
                  className={`my-1 flex justify-center items-center w-[35px] h-[35px] rounded-full transition duration-200 ${selectedLayer === 'satellite' ? 'bg-[#0287c2] text-white font-semibold' : 'bg-white text-black'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.25)" }}
                  onClick={() => setLayer('satellite')}
                  onMouseEnter={() => setLayerHovered('satellite')}
                  onMouseLeave={() => setLayerHovered(null)}
                >
                  <Icon className='text-2xl' icon="carbon:satellite" />
                </button> */}

                <button
                  className={`my-1 flex justify-center items-center w-[35px] h-[35px] rounded-full transition duration-200 ${selectedLayer === 'clouds' ? 'bg-[#0287c2] text-white font-semibold' : 'bg-white text-black'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.25)" }}
                  onClick={() => setLayer('clouds')}
                  onMouseEnter={() => setLayerHovered('clouds')}
                  onMouseLeave={() => setLayerHovered(null)}
                >
                  <Icon className='text-2xl' icon="material-symbols:cloud-outline" />
                </button>

                <button
                  className={`my-1 flex justify-center items-center w-[35px] h-[35px] rounded-full transition duration-200 ${selectedLayer === 'pm2p5' ? 'bg-[#0287c2] text-white font-semibold' : 'bg-white text-black'}`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.25)" }}
                  onClick={() => setLayer('pm2p5')}
                  onMouseEnter={() => setLayerHovered('pm2p5')}
                  onMouseLeave={() => setLayerHovered(null)}
                >
                  <Icon className='text-2xl' icon="material-symbols:factory-outline" />
                </button>

              </div>

              <div className={`left-[12px] lg:left-[24px] ${legendVisible || !isFullscreen ? 'hidden' : ''}`} style={{ position: 'absolute', transform: 'translateY(-50%)', top: '50%', zIndex: '1000' }}>
                <button
                  className={`flex justify-center items-center w-[24px] md:w-[35px] h-[24px] md:h-[35px] bg-white rounded-full transition duration-200 hover:opacity-90`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}
                  onClick={() => setLegendVisible(true)}
                >
                  <Icon className='text-3xl' icon="mdi:information-slab-symbol" />
                </button>
              </div>

              <div className={`left-[12px] lg:left-[24px] ${legendVisible && !mobileSelectorOpen && isFullscreen ? '' : 'hidden'}`} style={{ position: 'absolute', transform: 'translateY(-50%)', top: '50%', zIndex: '1000' }}>
                <div className='flex flex-row bg-white rounded-xl h-[260px] p-2' style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}>
                  <div className='h-[20px] w-[20px] cursor-pointer flex items-center justify-center rounded-full bg-[#d6ebf2] transition duration-200 hover:bg-[#add8e6]' onClick={() => setLegendVisible(false)} style={{ position: 'absolute', right: '4px', top: '4px' }}>
                    <Icon className='text-xl' icon="material-symbols-light:close" />
                  </div>
                  {selectedLayer === 'ptype' ? (
                    <div className='flex flex-row'>
                      <div className='h-[240px] w-[20px] rounded-full' style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.15)" }}>
                        <div className='h-[14.286%] rounded-t-xl' style={{ background: 'rgb(0,153,182)' }} />
                        <div className='h-[14.286%]' style={{ background: 'rgb(144,0,150)' }} />
                        <div className='h-[14.286%]' style={{ background: 'rgb(81,12,15)' }} />
                        <div className='h-[14.286%]' style={{ background: 'rgb(178,178,178)' }} />
                        <div className='h-[14.286%]' style={{ background: 'rgb(86,148,86)' }} />
                        <div className='h-[14.286%]' style={{ background: 'rgb(149,161,9)' }} />
                        <div className='h-[14.286%] rounded-b-xl' style={{ background: 'rgb(134,72,0)' }} />
                      </div>
                      <div className='flex flex-col text-xs text-center h-[240px] w-[100px] pl-1'>
                        <p className='flex justify-center items-center h-[14.286%]'>
                          Kiša
                        </p>
                        <p className='flex justify-center items-center h-[14.286%]'>
                          Ledena kiša
                        </p>
                        <p className='flex justify-center items-center h-[14.286%]'>
                          Mješoviti led
                        </p>
                        <p className='flex justify-center items-center h-[14.286%]'>
                          Snijeg
                        </p>
                        <p className='flex justify-center items-center h-[14.286%]'>
                          Mokar snijeg
                        </p>
                        <p className='flex justify-center items-center h-[14.286%]'>
                          Kiša sa snijegom
                        </p>
                        <p className='flex justify-center items-center h-[14.286%]'>
                          Ledene kuglice
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className='flex flex-row'>
                      <div className='h-[240px] w-[20px] rounded-full' style={{ background: legendStyle, boxShadow: "0 1px 5px rgba(0,0,0,0.15)" }} />
                      <div className='flex flex-col text-xs h-[240px] w-[50px] pl-1'>
                        {legendContent.map((content, index) => (
                          <p key={`legendContent-${index}`} className={`flex items-center h-[16.67%]`}>
                            {content}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={`inline-block ${isFullscreen ? 'hidden' : ''} bg-red-700 opacity-0`} onClick={() => setFullscreen(true)} style={{ position: 'absolute', bottom: '0px', zIndex: 1001, width: '100%', height: '100%' }}>
              </div>

              <div className='left-[12px] lg:left-[24px] top-[56px] md:top-[12px] lg:top-[24px]' style={{ position: 'absolute', zIndex: '1000' }}>
                <button
                  className={`flex ${isFullscreen ? '' : 'hidden'} justify-center items-center w-[24px] md:w-[35px] h-[24px] md:h-[35px] bg-white rounded-full transition duration-200 hover:opacity-90`} style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}
                  onClick={() => setFullscreen(false)}
                >
                  <Icon className='md:text-2xl' icon="carbon:return" />
                </button>
              </div>

              <div id="bogo" className={`${isFullscreen ? 'bottom-[72px] lg:bottom-[84px]' : 'top-[12px] lg:top-[24px]'} left-[12px] lg:left-[24px] absolute z-10`}>
                <img className="opacity-80 w-32 h-auto filter drop-shadow-[0 0 2px rgba(0,0,0,0.8)]" alt="Windy Logo" src="https://www.windy.com/img/logo201802/logo-full-windycom-white.svg" />
              </div>



              {/* Progress bar */}
              <div className='px-[12px] lg:px-[24px] bottom-[12px] lg:bottom-[24px]' style={{ position: 'absolute', zIndex: 401, width: '100%' }}>
                <div className="flex flex-row justify-center items-center bg-white rounded-full text-black" style={{ boxShadow: "0 1px 5px rgba(0,0,0,0.65)" }}>
                  <Icon
                    className="pl-1 cursor-pointer text-5xl mr-auto"
                    icon={isPlaying ? "emojione-monotone:pause-button" : "emojione-monotone:play-button"}
                    style={{ color: "#0287C2" }}
                    onClick={handlePlayPauseClick}
                  />
                  <div ref={scrollContainerRef} className="flex flex-row overflow-x-auto pl-1 rounded-r-full w-full custom-scrollbar">
                    {weatherTimeline.map((result, index) => (
                      <div
                        onClick={() => selectTimestamp(index)}
                        style={{ position: 'relative' }}
                        key={index}
                        className={`flex flex-col justify-start items-center py-1 cursor-pointer text-center ${(getAlternateShade(index) && !isSameDay(result, index)) ? 'border-l border-[#a2e2fe]' : ''} ${(getAlternateShade(index)) ? `${currentHour === index && !isSameDay(result, index) ? 'bg-[#FFC90C] border-none' : 'bg-[#e0f5ff]'}` : ''} ${isSameDay(result, index) ? '' : 'border-l border-[#a2e2fe] rounded-none w-[120px]'} ${(currentHour === index && !isSameDay(result, index)) ? 'bg-[#FFC90C] rounded-full' : ''} ${currentHour === index ? 'highlighted' : ''}`}
                      >
                        <hr
                          className={`${currentHour === index ? 'inline-block' : 'hidden'}`}
                          style={{
                            position: 'absolute',
                            height: '100%',
                            width: '1px',
                            background: '#FFC90C',
                            left: '',
                            transform: 'translateX(-50%) translateY(-4px)',
                            zIndex: 1
                          }}
                        />
                        <p className={`px-2 z-20 text-sm ${isSameDay(result, index) ? '' : 'w-max'}`}>{formatDate(result, index)}</p>
                        <p className={`mt-auto px-2 z-20 text-sm ${(isSameDay(result, index) && currentHour === index) ? 'bg-[#FFC90C] rounded-full' : ''}`}>{formatTime(result, index, weatherTimeline)}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>

  );
};

export default WindyMap;